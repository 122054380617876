
// 物理机
let baseUrl = VUE_APP_URL.baseUrl;

// 4.0线上
// let baseUrl = 'https://gdapi.hiails.com';


// 4.0线上 中建三局
// let baseUrl = 'https://zjsjapi.hiaipc.com'; 
export {
	baseUrl
}
