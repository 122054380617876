import axios from 'axios'; 
import {baseUrl} from '@/js/util.js'



// 
export const GetComponentModelList=(datas,token)=>{ // 获取模型数据结构
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/WeChatSafety/GetComponentModelList`,
        params:datas,
        headers: {'token': token},
    })
}

export const GetBIMlList=(datas,token)=>{ // 获取模型列表
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/WeChatSafety/GetBIMlList`,
        params:datas,
        headers: {'token': token},
    })
}

export const AddProductionSchedule=(datas,token)=>{ // 新增生产进度
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/WeChatProduction/AddProductionSchedule`,
        data:datas,
        headers: {'token': token},
    })
}

export const GetModelList=(datas,token)=>{ // 获取某个模型上的生产进度
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/WeChatProduction/GetModelList`,
        params:datas,
        headers: {'token': token},
    })
}

export const GetWeChatDeviceList=(datas,token)=>{ // 数字工地 获取设备列表
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/WeChatDigitalSite/GetWeChatDeviceList`,
        params:datas,
        headers: {'token': token},
    })
}
