export class ViewerInitializer{
  constructor() {
    this.build = new OBV.Api.ObvBuilder();
  }

  async loadViewModel({
    applicationOptions,
    viewerModelItemList,
    viewerContainer,
    config3d
  },callback) {
    const build = this.build;
    const application = await this.buildApplication(applicationOptions);
    const obvDocumentModelList = await this.loadObvDocumentList(
      build,
      application,
      viewerModelItemList
    );
    const v3dModelList = this.getObvDocumentView3dList(obvDocumentModelList);
    const isLoad3d = v3dModelList && v3dModelList.length > 0;
    let modelsArr = []
    let guid;
    viewerModelItemList.forEach((item) =>{
      if(item.guid){
        guid = item.guid
      }
    })

    if (isLoad3d) {
      if(guid){
        for (const item of v3dModelList) {
          const get3DMess = item.obvDocument.get3dGeometryItems();

          for (const data of get3DMess){
            if(data.guid == guid){
              // 创建 viewer 容器, 创建API
              const obvApi = await build.buildViewer3d(
                  application,
                  viewerContainer,
                  config3d
              );
              // 获取三维视图
              build.load3dModels(obvApi, {
                  obvDocument: item.obvDocument,
                  viewer3dItem: data,
              })
              callback(obvApi, application);
            }
          }
        }
      }else{
        // 3d
        const obvApi = await build.buildViewer3d(
          application,
          viewerContainer,
          config3d
        );
        
        for (const item of v3dModelList) {
          try{
            build.load3dModels(obvApi, item).then((model) => {
                  modelsArr.push(model);
               /* GetModelIndexAndUrnArr(model);*/
            })
          }catch(e){
            
          }
        }
        return { obvApi, application,modelsArr };
      }
    }

    const v2dModel = this.getObvDocumentView2dItem(obvDocumentModelList);
    if (v2dModel && v2dModel.viewer2dItem) {
      if(guid){
        const get2DMess = v2dModel.obvDocument.get2dGeometryItems();

        for (const data of get2DMess){
          if(data.guid == guid){
            // 创建 viewer 容器, 创建API
            const obvApi = await build.buildViewer2d(application, viewerContainer);
            // 获取二维视图
            build.load2dModels(obvApi, {
                obvDocument: v2dModel.obvDocument,
                viewer2dItem: data,
            });
            callback(obvApi, application);
          }
        }
      }else{
        const obvApi = await build.buildViewer2d(
          application,
          viewerContainer,
          config3d
        );
        this.listener2dError(obvApi);
        await build.load2dModels(obvApi, v2dModel);
        return { obvApi, application,modelsArr};
      }
    }

    const vdocModelItem = this.getObvDocumentVdocItem(obvDocumentModelList);
    if (vdocModelItem && vdocModelItem.viewerDocItem) {
      if(guid){
        const docItems = vdocModelItem.obvDocument.getDocItems();
        for (const data of docItems){
          if(data.guid == guid){
            // 创建 viewer 容器, 创建API
            const obvApi = await build.buildViewerDoc(application, viewerContainer);
            // 获取二维视图
            build.loadDocModels(obvApi, vdocModelItem.obvDocument,data);
            callback(obvApi, application);
          }
        }
      }else{
        // doc 只能加载一个
        const docApi = await build.buildViewerDoc(application, viewerContainer, config3d);
        build.loadDocModels(
          docApi,
          vdocModelItem.obvDocument,
          vdocModelItem.viewerDocItem
        );
        return { obvApi: docApi, application,modelsArr };
      }
    }
  }

  listener2dError(obvApi) {
    obvApi.addEventListener(OBV.ViewerEventTypes.V2dError, event => {
      console.log("文件加载失败，请检查原始文件是否正确！",event);
    });
  }

  async buildApplication(applicationOptions) {
    try {
      if (!applicationOptions.refreshToken) {
        applicationOptions.refreshToken = applicationOptions.getAccessToken;
      }
      return this.build.buildApplication(applicationOptions);
    } catch (e) {
      throw new Error("buildApplication error" + e);
    }
  }

  async loadDocument(application, urn) {
    try {
      return this.build.loadDocument(application, urn);
    } catch (e) {
      throw new Error("loadDocument error" + e);
    }
  }

  getObvDocumentView3dList(obvDocumentModelList) {
    const list = [];
    for (const item of obvDocumentModelList) {
      if (item.obvDocument) {
        const v3dItems = item.obvDocument.getViewer3dItem(item.guid);
        v3dItems &&
          list.push({
            obvDocument: item.obvDocument,
            viewer3dItem: v3dItems,
            modelOffset: item.offset
          });
      }
    }
    return list;
  }

  getObvDocumentView2dItem(obvDocumentModelList) {
    let viewer2d;
    const obvDocumentModel = obvDocumentModelList.find(item => {
      const v2dItem = item.obvDocument.getViewer2dItem(item.guid);
      if (v2dItem) {
        viewer2d = v2dItem;
        return true;
      }
    });
    if (obvDocumentModel) {
      return {
        obvDocument: obvDocumentModel.obvDocument,
        viewer2dItem: viewer2d
      };
    }
  }

  getObvDocumentVdocItem(obvDocumentModelList) {
    const obvDocumentModel = obvDocumentModelList.find(item => {
      const vdocItem = item.obvDocument.getViewerDocItem(item.guid);
      if (vdocItem) {
        return vdocItem;
      }
    });
    if (obvDocumentModel) {
      return { obvDocument: obvDocumentModel.obvDocument, viewerDocItem: obvDocumentModel };
    }
  }

  async loadObvDocumentList(build, application, viewerModelItemList) {
    const obvDocumentList = [];
    for (const item of viewerModelItemList) {
      const obvDocument = await build.loadDocument(
        application,
        item.documentUrn
      );
      obvDocumentList.push({
        obvDocument,
        guid: item.guid,
        offset: item.offset
      });
    }
    return obvDocumentList;
  }
}